/*
  This hook detects the screen size. Based on this tutorial:
  https://medium.com/@josephat94/building-a-simple-react-hook-to-detect-screen-size-404a867fa2d2

  It's a great way to get the screen size, but it is not automatically responsive.
*/

// useScreenSize.js
import { useState, useEffect } from 'react'

const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    window.addEventListener('resize', handleResize)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return screenSize
}

export default useScreenSize
